import React from 'react';
import Slider from 'react-slick';
import './Home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Home() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div>
      <div className="main-content">
        <Slider {...settings}>
          <div className="slide">
            <img src="/images/slider4.png" alt="Slide 1" />
            <div className="slide-content">

            </div>
          </div>
          <div className="slide">
            <img src="/images/slider1.png" alt="Slide 1" />
            <div className="slide-content">
              <h2>Welcome to <br /> Shiva Sai Marketing</h2>
              <p>Harness the power of the sun with Shiva Sai Marketing's efficient and durable solar <br /> panels for a sustainable and cost-effective energy solution.</p>
            </div>
          </div>
          <div className="slide">
            <img src="/images/slider2.jpg" alt="Slide 2" />
            <div className="slide-content">
              <h2>Maximum Efficiency</h2>
              <p>Experience unmatched energy efficiency with Shiva Sai Marketing's  state-of-the-art solar panels, designed<br /> to optimize power generation.</p>
            </div>
          </div>
          <div className="slide">
            <img src="/images/slider3.jpg" alt="Slide 3" />
            <div className="slide-content">
              <h2>The World`s <br /> Most Best Quality</h2>
              <p>Enjoy reliable and superior performance with Shiva Sai Marketing's high-quality solar panels, <br />ensuring consistent energy production.</p>
            </div>
          </div>
        </Slider>
      </div>
      <div className="why-choose-us">
        <div className="why-choose-us-content">
          <img src="/images/choose.jpg" alt="Solar Power Grids" className="why-choose-us-image" style={{ animationDelay: '2s' }} />
          <div className="why-choose-us-text">
            <h2>Why Choose Our Solar Power Grids?</h2>
            <h3>Quality Assurance:</h3>
            <p>All products are manufactured to the highest standards and backed by extensive warranties.</p>

            <h3>Expert Support:</h3>
            <p>Our team of professionals is here to assist you from installation to maintenance.</p>

            <h3>Sustainability Commitment:</h3>
            <p>Join us in our mission to promote renewable energy and protect the environment.</p>
          </div>
        </div>
      </div>
      <div className="features">
        <h2>Solar Panel Features</h2>
        <div className="features-content">
          <div className="features-column">
            <div className="feature">
              <i className="fa fa-cogs"></i>
              <h3>Low Cost Maintenance</h3>
              <p>Affordable solar maintenance solutions ensure optimal performance and longevity of your solar systems, reducing costs and downtime.</p>
            </div>
            <div className="feature">
              <i className="fa fa-battery-full"></i>
              <h3>High Efficiency</h3>
              <p>High-efficiency solar panels convert more sunlight into energy, providing better performance and energy savings.</p>
            </div>
          </div>
          <div className="feature-image">
            <img src="/images/solor.jpg" alt="Solar Image" />
          </div>
          <div className="features-column">
            <div className="feature">
              <i className="fa fa-sun"></i>
              <h3>Solar Powered</h3>
              <p>Solar-powered systems provide a sustainable and eco-friendly energy solution, reducing reliance on non-renewable resources.</p>
            </div>
            <div className="feature">
              <i className="fa fa-shield"></i>
              <h3>Durable Design</h3>
              <p>Our solar panels are built with a durable design to withstand harsh weather conditions and ensure long-lasting performance.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="faqs">
        <h2>FAQS</h2>
        <div className="faqs-content">
          <h3>1. What is a 3kW on-grid solar system?</h3>
          <p>A 3kW on-grid solar system is a solar power setup designed to generate 3 kilowatts of electricity from the sun. It is connected to the electrical grid, allowing excess energy to be exported to the grid and providing power when the sun isn’t shining.</p>

          <h3>2. How many solar panels do I need for a 3kW system?</h3>
          <p>The number of solar panels required for a 3kW system can vary depending on the panel’s wattage and efficiency. On average, it might take 8-12 solar panels with a capacity of around 250-350 watts each.</p>

          <h3>3. What is the cost of a 3kW solar system?</h3>
          <p>The cost of a 3kW solar system can vary by location, quality of components, and installation costs. On average, it might cost around Rs. 210000.00.</p>

          <h3>4. How much electricity will a 3kW system produce?</h3>
          <p>The electricity production of a 3kW system depends on factors like location, panel orientation, shading, and weather. On average, it can produce approximately 3,600-4,800 kWh (kilowatt-hours) of electricity annually.</p>

          <h3>5. What are the benefits of an on-grid solar system?</h3>
          <p>On-grid solar systems can reduce your electricity bills, earn you credits for excess power, and reduce your carbon footprint. They are also typically more affordable and straightforward to install than off-grid systems.</p>

          <h3>6. Do I need batteries with a 3kW on-grid system?</h3>
          <p>It’s not necessary to have batteries with an on-grid system, but they can be added for backup power during grid outages or to store excess energy for nighttime use.</p>

          <h3>7. What maintenance is required for a 3kW on-grid solar system?</h3>
          <p>On-grid systems require minimal maintenance. Regular cleaning of the solar panels and occasional inspection of the system’s components are typically sufficient.</p>

          <h3>8. Are there any incentives or rebates for installing a 3kW solar system?</h3>
          <p>Many regions offer incentives, tax credits, or rebates for solar installations. It’s advisable to check with local and federal authorities for available incentives.</p>

          <h3>9. Can I install a 3kW system myself?</h3>
          <p>While it’s possible to install a solar system yourself, it’s recommended to hire a professional installer. They have the expertise to ensure a safe, efficient, and code-compliant installation.</p>

          <h3>10. How long does it take to recoup the investment in a 3kW on-grid solar system?</h3>
          <p>The payback period for a solar system can vary depending on factors like installation costs, local electricity rates, and available incentives. On average, homeowners may see a return on investment in 5 to 10 years.</p>

          <h3>11. What happens if my solar system produces more electricity than I use?</h3>
          <p>Excess electricity is typically exported to the grid, and in many cases, you can receive credits or compensation from your utility company, depending on local regulations (net metering).</p>

          <h3>12. Is my roof suitable for a 3kW solar system?</h3>
          <p>A professional solar installer can assess your roof’s suitability based on factors like orientation, tilt, shading, and structural integrity.</p>
        </div>

      </div>
    </div>
  );
}
