import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

export default function Navbar() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isMobileMenuOpen]);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleProductsDropdown = () => {
        setIsProductsDropdownOpen(!isProductsDropdownOpen);
    };

    return (
        <div className="navbar-container">
            <div className="main-navbar">
                <div className="logo-container">
                    <a href="/"><img src="/images/logo.jfif" alt="Logo" className="logo" /></a>
                </div>
                <nav className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
                    <ul>
                        <li><a href="/Kw">Products</a></li>
                        <li><a href="/Legal">Legal</a></li>
                        <li><a href="/About">About Us</a></li>
                        <li><a href="/images/ssmplan.pdf" target="_blank" rel="noopener noreferrer">Plan PDF</a></li>
                        <li><a href="http://in.shivasaimarketing.com/">Virtual Office</a></li>
                        <li><a href="/Contact">Contact Us</a></li>
                    </ul>
                </nav>
                <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                    <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
                </div>
            </div>
        </div>
    );
}
