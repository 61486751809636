import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Legal from './components/Legal/Legal';
import AboutUs from './components/Aboutus/Aboutus';
import Kw from './components/Kw/Kw';
import Contact from './components/Contact/Contact';
import Socialmedia from './Socialmedia';
import Footer from './Footer'
function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Socialmedia />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Legal' element={<Legal />} />
          <Route path='/About' element={<AboutUs />} />
          <Route path='/Kw' element={<Kw />} />
          <Route path='/Contact' element={<Contact/>} />
          {/* applications start */}
        </Routes>    
         <Footer/>
          </div>
    </Router>
  );
}


export default App;
