import React from 'react';
import './Footer.css';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <p>Copyright © {currentYear} SHIVA SAI MARKETING  All Rights Reserved.</p>
      </div>
    </footer>
  );
}
