import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import './About.css';

const AboutUs = () => {
    return (
        <div>
            <Container maxWidth="lg">
                <Box my={4}>
                    <div className="about-container">
                        <img
                            src='/images/about.jpg'
                            alt='about'
                            className="about-image"
                        />
                        <Typography
                            variant="h5"
                            component="div"
                            className="about-text"
                        >
                            About Us
                        </Typography>
                    </div>
                    <Typography variant="body1" paragraph style={{ color: 'gray', fontWeight: '300', marginTop: '20px' }}>
                        Welcome to Shivasai Marketing, where our passion for excellence drives us to deliver top-notch marketing solutions for businesses of all sizes. Our team of dedicated professionals is committed to helping you achieve your marketing goals and elevate your brand to new heights.
                    </Typography>
                    <Typography variant="body1" paragraph style={{ color: 'gray', fontWeight: '300', }}>
                        At Shivasai Marketing, we understand the dynamic nature of the marketing landscape. We specialize in providing innovative and customized marketing strategies that cater to the unique needs of each client. From digital marketing and social media management to content creation and SEO, we offer a comprehensive suite of services designed to enhance your brand's visibility and engagement.
                    </Typography>
                    <Typography variant="body1" paragraph style={{ color: 'gray', fontWeight: '300', }}>
                        Our mission is to empower businesses by leveraging the latest marketing technologies and trends. We believe in building strong, long-term relationships with our clients, and our collaborative approach ensures that we are always aligned with your vision and objectives.
                    </Typography>
                    <Typography variant="body1" paragraph style={{ color: 'gray', fontWeight: '300', }}>
                        Shivasai Marketing is committed to delivering measurable results and exceptional value. Our team stays ahead of the curve by continually adapting to industry changes and employing cutting-edge techniques to drive your success.
                    </Typography>
                    <Typography variant="body1" paragraph style={{ color: '#0a4f88', fontWeight: '800', }}>
                        Thank you for considering Shivasai Marketing as your trusted marketing partner. We look forward to working with you to create impactful marketing campaigns that drive growth and success.
                    </Typography>
                </Box>
            </Container>
        </div>
    );
};

export default AboutUs;
