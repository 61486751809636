import React from 'react'
import './Legal.css'

export default function Legal() {
  return (
    <div>
      <div className="legal-container">
        <img src='/images/legal.jpg' alt='legalpaper1' className='legal' />
        <img src='/images/ssmmsm.jpg' alt='legalpaper2' className='legal' />
      </div>
    </div>
  )
}
