/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div>
            <div className="contact-container">
                <div className="contact-info">
                    <h1>Corporate Office</h1>
                    <p>Shiva Sai Marketing , Street/Road Anjanapuri Colony,
                        Address no 1-1-55-/15/2, Beside Ramalayam temple Road no 9, Suryapet, Telangana, 508213</p>
                    <h2><strong>Toll Free:</strong></h2>
                    <p>+91 99084-24-365</p>
                    <h2><strong>E-mail:</strong></h2>
                    <p>support@shivasaimarketing.com</p>
                </div>
                <div className="contact-image">
                    <img src="/images/cont.jpg" alt="Corporate Office" />
                </div>
            </div>
            <div className="contact-map-section">
                <div className="map-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3812.2472388515816!2d79.60873797515974!3d17.158180183690494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDA5JzI5LjUiTiA3OcKwMzYnNDAuNyJF!5e0!3m2!1sen!2sin!4v1723467135944!5m2!1sen!2sin" 
                        width="100%"
                        height="460"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default Contact;
