import React from 'react';
import './Kw.css';

export default function Kw() {
    return (
        <div>
            <div className="page-container">
                <div className="kw-container">
                    <div className="kw-content">
                        <img
                            // src="/images/1kw.webp"
                            src="/images/1kw1.jpg"
                            alt="Solar Panels"
                            className="kw-image"
                        />
                        <div className="kw-text">
                            <h1>1KW (Kilo Watt)</h1>
                            <ul>
                                <li>High efficiency solar panels</li>
                                <li>Durable and weather-resistant</li>
                                <li>Low maintenance cost</li>
                                <li>Environmentally friendly</li>
                                <li>Easy installation</li>
                                <li>Cost-effective energy solution</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="kw-container reverse">
                    <div className="kw-content">
                        <img
                            src="/images/3kw.jpg"
                            alt="Solar Panels"
                            className="kw-image"
                        />
                        <div className="kw-text">
                            <h1>3KW (Kilo Watt)</h1>
                            <ul>
                                <li>High efficiency solar panels</li>
                                <li>Durable and weather-resistant</li>
                                <li>Low maintenance cost</li>
                                <li>Environmentally friendly</li>
                                <li>Easy installation</li>
                                <li>Cost-effective energy solution</li>
                            </ul>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
    );
}
